<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-button v-throttle type="danger" @click="delBtn">批量删除</el-button>
      <el-button
        v-if="contentList.length"
        type="primary"
        @click="updateTerminalApiFunc"
        v-throttle
        >开始监测</el-button
      >
    </div>
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <div>
        <el-form
          :inline="true"
          :model="paramForm"
          class="demo-form-inline"
          style="margin-bottom: -20px"
        >
          <el-form-item label="IP" class="formItemBoxStyle">
            <el-input
              @change="changParam"
              v-model="paramForm.ipAddress"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="考场名称" class="formItemBoxStyle">
            <el-input
              @change="changParam"
              v-model="paramForm.sysOrgExamPlaceRoomName"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="监测状态" class="formItemBoxStyle">
            <el-select @change="changParam" v-model="paramForm.itemStatus">
              <el-option label="全部" value=""></el-option>
              <el-option label="正常" :value="1"></el-option>
              <el-option label="异常" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button v-throttle type="primary" @click="changParam"
              >查询</el-button
            >
            <el-button v-throttle @click="resetBtn">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-popover placement="bottom-start" width="500" trigger="hover">
          <div style="height: 706px; font-size: 16px">
            <div>
              提示：点击开始监测后，耐心等待几秒后手动点击查询进行刷新页面
            </div>
            程序服务监测：
            <el-table :data="tipTable1" border style="margin-bottom: 18px">
              <el-table-column
                property="name"
                label="检查项目"
              ></el-table-column>
              <el-table-column
                property="content"
                label="检查内容"
              ></el-table-column>
            </el-table>
            运行环境监测:
            <el-table :data="tipTable2" border>
              <el-table-column
                property="name"
                label="检查项目"
              ></el-table-column>
              <el-table-column
                property="content"
                label="检查内容"
              ></el-table-column>
            </el-table>
          </div>

          <i
            slot="reference"
            style="cursor: pointer"
            class="el-icon-warning"
          ></i>
        </el-popover>
        监考端相关参数检测说明
      </div>
    </div>
    <!-- type 切换 -->
    <el-tabs v-model="activeType" @tab-click="handleClick">
      <el-tab-pane
        v-for="item in activeList"
        :key="item.type"
        :label="item.name"
        :name="item.type"
      >
      </el-tab-pane>
    </el-tabs>
    <!-- 内容盒子 -->
    <div style="display: flex; height: 24px">
      <el-checkbox @change="allChecked">全选</el-checkbox>
    </div>
    <div class="activeContentBox" v-loading="loading">
      <template v-if="contentList.length">
        <div class="contentLiBox" v-for="(item, i) in contentList" :key="i">
          <el-checkbox
            style="position: absolute; top: 6px; left: 10px"
            v-model="item.checkbox"
          ></el-checkbox>
          <div class="contentLiTop">
            <div class="contentLiTopImg">
              <img src="@/assets/onlineLogo.png" />
            </div>
            <div class="contentLiTopRight">
              <div>
                <span>IP地址<i></i></span>{{ item.ipAddress }}
              </div>
              <div>
                <span>mac地址<i></i></span>{{ item.macAddress }}
              </div>
              <div>
                <span>考场<i></i></span>{{ item.sysOrgExamPlaceRoomName }}
              </div>
              <!-- <div><span>辅考场<i></i></span></div> -->
              <div>
                <span>监测时间<i></i></span
                >{{ item.currentDetectionTime | dayList }}
              </div>
              <el-button @click="dialogVisibleBtn(item)" type="text"
                >查看监测明细》</el-button
              >
            </div>
          </div>
          <div class="contentLi">
            <div class="li">
              <span>程序服务<i></i></span>
              <div v-if="!item.itemType1err.length" style="color: #0b8c00">
                正常
              </div>
              <div v-else style="color: #fb0505">异常</div>
            </div>
            <div class="liText">
              <div v-for="(err, iErr) in item.itemType1err" :key="iErr">
                {{ iErr + 1 }}.{{ err.itemName }}：{{ err.itemErrorContent }}
              </div>
            </div>
          </div>
          <div class="contentLi">
            <div class="li">
              <span>运行环境<i></i></span>
              <div v-if="!item.itemType2err.length" style="color: #0b8c00">
                正常
              </div>
              <div v-else style="color: #fb0505">异常</div>
            </div>
            <div class="liText">
              <div class="liText">
                <div v-for="(err, iErr) in item.itemType2err" :key="iErr">
                  {{ iErr + 1 }}.{{ err.itemName }}：{{ err.itemErrorContent }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="voidBox">
          <div style="width: auto; text-align: center; margin-top: -100px">
            <div style="font-size: 20px; margin-bottom: 10px; color: #c4b6b6">
              点击开始监测后，耐心等待几秒后手动点击查询进行刷新页面
            </div>
            <el-button type="primary" @click="updateTerminalApiFunc" v-throttle
              >开始监测</el-button
            >
          </div>
        </div>
      </template>
    </div>
    <!-- 监测明细弹框 -->
    <el-dialog title="监测明细" :visible.sync="dialogVisible" width="900px">
      <div class="dialogVisibleTit">
        <i
          v-if="!!itemType2.find((e) => e.itemStatus == 0)"
          style="color: #ff2e18"
          class="el-icon-warning"
        ></i>
        <i v-else style="color: #1890ff" class="el-icon-success"></i>
        运行环境
        <div>
          已监测<span>{{ itemType2.length }}</span
          >项
        </div>
      </div>
      <div class="dialogVisibleContent">
        <div
          class="dialogVisibleContentLi"
          v-for="(item, i) in itemType2"
          :key="i"
        >
          <div>
            <span>{{ item.itemName }}</span
            ><i></i>
          </div>
          <span>{{ item.itemContent }}</span>
          <span style="color: #ff2e18">{{ item.itemErrorContent }}</span>
        </div>
      </div>
      <div class="dialogVisibleTit">
        <i
          v-if="!!itemType1.find((e) => e.itemStatus == 0)"
          style="color: #ff2e18"
          class="el-icon-warning"
        ></i>
        <i v-else style="color: #1890ff" class="el-icon-success"></i>程序服务
        <div>
          已监测<span>{{ itemType1.length }}</span
          >项
        </div>
      </div>
      <div class="dialogVisibleContent">
        <div
          class="dialogVisibleContentLi"
          v-for="(item, i) in itemType1"
          :key="i"
        >
          <div>
            <span>{{ item.itemName }}</span
            ><i></i>
          </div>
          <span>{{ item.itemContent }}</span>
          <span style="color: #ff2e18">{{ item.itemErrorContent }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import {
  getTerminalApi,
  updateTerminalApi,
  delTerminalApi,
} from '@/api/system/terminalMonitor.js'
export default {
  name: 'terminalMonitor',
  data() {
    return {
      tipTable1: [
        { name: '时间同步', content: '检查监考端和校级平台时间是否同步' },
        { name: '录制服务', content: '是否正在运行' },
        { name: 'MQTT服务', content: '是否正在运行' },
        { name: '监考端FTP服务', content: '是否正在运行' },
      ],
      tipTable2: [
        { name: '学生端版本', content: '是否配套版本' },
        { name: '平台端版本', content: '是否配套版本' },
        {
          name: 'CPU',
          content: '设备cpu',
        },
        { name: '内存', content: '16.00GB（双通道）' },
        { name: '硬盘', content: '1TB' },
        { name: '考场设置', content: '通信IP地址是否连接' },
        { name: '考场设置', content: '校级IP地址是否连接' },
      ],
      activeType: '31',
      activeList: [
        // { name: '平台后端', type: '11' },
        // { name: '平台前端', type: '21' },
        { name: '监考端', type: '31' },
        // { name: '学生终端', type: '41' },
        // { name: '学生终端FTP服务', type: '42' },
        // { name: '阅卷终端', type: '51' },
        // { name: 'AI算力盒', type: '61' },
      ],
      // 条件
      paramForm: {
        ipAddress: '',
        sysOrgExamPlaceRoomName: '',
        state: '',
      },
      contentList: [],
      loading: false,
      //
      dialogVisible: false,
      itemType1: [],
      itemType2: [],
    }
  },
  mounted() {
    this.getTerminalApiFunc()
  },
  methods: {
    handleClick(tab, event) {
      this.getTerminalApiFunc()
    },
    getTerminalApiFunc() {
      this.loading = true
      let conditionObj = JSON.parse(JSON.stringify(this.paramForm))
      // 条件修改
      for (let key in conditionObj) {
        if (conditionObj[key] === '' || conditionObj[key] === undefined) {
          delete conditionObj[key]
        }
      }
      const type = Number(this.activeType)
      getTerminalApi(type, conditionObj)
        .then((res) => {
          this.loading = false
          if (res.success) {
            // res.data.forEach(e => {
            //   e.itemType1 = e.detectionItemDTOList.filter(item => item.itemType === 1);
            //   e.itemType2 = e.detectionItemDTOList.filter(item => item.itemType === 2);
            //   e.itemType1err = e.detectionItemDTOList.filter(item => item.itemStatus === 0 && item.itemType !== 1);
            //   e.itemType2err = e.detectionItemDTOList.filter(item => item.itemStatus === 0 && item.itemType !== 2);
            // });
            res.data.forEach((e) => {
              const { detectionItemDTOList } = e
              const filterItems = (type, status) =>
                detectionItemDTOList.filter(
                  (item) => item.itemType === type && item.itemStatus !== status
                )
              Object.assign(e, {
                itemType1: filterItems(1),
                itemType2: filterItems(2),
                itemType1err: filterItems(1, 1),
                itemType2err: filterItems(2, 1),
                checkbox: false,
              })
            })
            this.contentList = res.data
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 开始监测
    updateTerminalApiFunc() {
      updateTerminalApi(Number(this.activeType))
        .then((res) => {
          if (res.success) {
            this.$message.success('发送成功')
            this.getTerminalApiFunc()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    },
    changParam() {
      this.getTerminalApiFunc()
    },
    resetBtn() {
      this.paramForm = {
        ipAddress: '',
        sysOrgExamPlaceRoomName: '',
        state: '',
      }
      this.getTerminalApiFunc()
    },
    delBtn() {
      let checkList = this.contentList
        .filter((e) => e.checkbox)
        .map((e) => e.detectionUnionCode)

      if (checkList.length > 0) {
        delTerminalApi(Number(this.activeType), checkList)
          .then((res) => {
            if (res.success) {
              this.getTerminalApiFunc()
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((err) => {})
      } else {
        this.$message.error('请选择一台设备！')
      }
    },
    dialogVisibleBtn(row) {
      let { itemType1, itemType2 } = row
      this.itemType1 = itemType1
      this.itemType2 = itemType2
      this.dialogVisible = true
    },
    // 全选
    allChecked(flag) {
      this.contentList.forEach((e) => {
        e.checkbox = flag
      })
      this.$forceUpdate()
    },
  },
  watch: {},
  filters: {
    dayList(val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>
<style lang="scss" scoped>
.activeContentBox {
  height: calc(100vh - 264px);
  width: 100%;
  overflow-y: auto;
  background: #fff;
  box-sizing: border-box;
  margin-top: 10px;
  position: relative;
  .voidBox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.contentLiBox {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #c8c5c5;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  .contentLiTop {
    padding-left: 80px;
    position: relative;
    min-height: 60px;
    .contentLiTopImg {
      position: absolute;
      width: 50px;
      height: 50px;
      font-size: 0;
      vertical-align: middle;
      overflow: hidden;
      top: 5px;
      left: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .contentLiTopRight {
      display: flex;
      align-items: center;
      min-height: 50px;
      div {
        display: inline-flex;
        margin-right: 50px;
      }
    }
  }
  .contentLi {
    display: flex;
    .li {
      display: flex;
      width: 120px;
      justify-content: space-between;
      margin-right: 60px;
      flex: 1 0 1;
      div {
        width: 40px;
      }
    }
    .liText {
      div {
        display: inline-block;
        margin-right: 50px;
      }
    }
  }
  span {
    color: #a8a8a8;
    display: block;
    position: relative;
    padding-right: 6px;
    width: 70px;
    height: 30px;
    text-align: justify;
    &::before {
      content: ':';
      position: absolute;
      right: 2px;
    }
    i {
      display: inline-block;
      width: 100%;
    }
  }
}

.dialogVisibleTit {
  font-size: 20px;
  i {
    margin-right: 10px;
  }
  span {
    color: #1890ff;
    margin: 0 6px;
  }
  div {
    display: inline-block;
    margin-left: 40px;
    font-size: 16px;
  }
}
.dialogVisibleContent {
  background: #f9f9f9;
  padding: 10px 30px;
  margin: 10px 0 20px;
  .dialogVisibleContentLi {
    font-size: 16px;
    div {
      display: inline-block;
      margin-right: 40px;
      span {
        color: #a8a8a8;
        display: block;
        width: 100px;
        height: 30px;
        text-align: justify;
        i {
          display: inline-block;
          width: 100%;
        }
      }
    }
  }
}
</style>

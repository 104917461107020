import { request, noTimeOutReq } from '@/utils/request.js'

export function getTerminalApi (type, data) {
  return request({
    url: `/terminal/web/detection/terminal/${type}/get`,
    method: 'post',
    data
  })
}
export function updateTerminalApi (type) {
  return request({
    url: `/terminal/web/detection/terminal/command/${type}/update`,
    method: 'post',
  })
}

export function delTerminalApi (type, data) {
  return request({
    url: `/terminal/web/detection/terminal/${type}/delete`,
    method: 'post',
    data
  })
}